import { createRouter, createWebHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home/HomeView.vue'),
    meta: { title: 'Aroon Tech' },
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services/ServicesView.vue'),
    meta: { title: 'Services' },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/Projects/ProjectsView.vue'),
    meta: { title: 'Projects' },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About/AboutView.vue'),
    meta: { title: 'About me' },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.afterEach((to) => {
  document.title = to.meta.title || 'Home';
});
export default router
